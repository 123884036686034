<template>
  <div class="contents">
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.url"
        prop="url"
        name="url"
        label="Embed URL"
        description="Add the URL you want to embed into the page."
      />
    </div>
    <div class="w-full px-2">
      <vue-input-group
        v-model="item.content.height"
        prop="height"
        name="height"
        label="Embed Height"
        description="Enter how tall you want the embed to be on the page."
      />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>
